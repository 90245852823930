import "../../css/pages/home.scss";
import lottie from "lottie-web";
import BasePage from "./base";
import nav from "../modules/nav";
import pillarScroller from "../modules/pillarScroller";
import sliderContainer from "../modules/sliderContainer";
import fullSlider from "../modules/fullSlider";

class InnovatePage extends BasePage {
    constructor(...opts) {
        super(...opts);
    }

    init() {
        super.init();
        super.initRellax();
    }

    onReady() {
        super.onReady();
        const self = this;
        this.homeHero = document.querySelector(".homepageHero");
        this.homeHeroVideo = document.querySelector(".homepageHero__video");
        this.nav = document.querySelector("nav");
        this.lottiePlayer = document.querySelector(".lottiePlayer");

        this.lastScrollTop = 0;
        this.isNavStuck = false;
        this.isNavStuckOpen = false;
        this.mobileNavButton = document.querySelector(".nav__mobileBtn"); //.display == "none";
        this.mobileNavDisplay = window.getComputedStyle(this.mobileNavButton).display;
        this.isMobileNav = this.mobileNavDisplay === "block";

        if (!document.body.classList.contains("magnolia-editmode")) {
            self.onScroll();
            window.addEventListener("scroll", function() {
                self.onScroll();
            });
            window.addEventListener("resize", function() {
                self.onScroll();
            });
            window.addEventListener("orientationchange", function() {
                self.onScroll();
            });
        }

        pillarScroller.init();
        sliderContainer.autoplay();
        sliderContainer.autoplayOFF();
        //fullSlider.autoplay();
        fullSlider.autoplayOFF();
        self.loadLottiePlayer();
    }

    reportWindowSize() {
        let innerHeight = window.innerHeight;
        return innerHeight;
    }

    onScroll() {
        this.checkHeroPos();
    }

    checkHeroPos() {
        try {
            let homeHeroBoundingRec = this.homeHero.getBoundingClientRect();
            let homeHeroHeight = this.homeHero.clientHeight;

            if (homeHeroBoundingRec == null) {
                return;
            }

            if (homeHeroBoundingRec) {
                if (!this.isMobileNav) {
                    if (Math.abs(homeHeroBoundingRec.top) >= homeHeroHeight / 4) {
                        if (!this.isNavStuck) {
                            nav.prepareStuckNav(false);
                            this.isNavStuck = true;
                        }
                    } else {
                        if (this.isNavStuck) {
                            nav.prepareStuckNav(true);
                            this.isNavStuck = false;
                        }
                    }

                    if (homeHeroBoundingRec.bottom <= homeHeroHeight / 2) {
                        if (!this.isNavStuckOpen) {
                            nav.toggleStuckNav(false);
                            this.isNavStuckOpen = true;
                        }
                    } else {
                        if (this.isNavStuckOpen) {
                            nav.toggleStuckNav(true);
                            this.isNavStuckOpen = false;
                        }
                    }
                }

                // to check if the video is video tag
                if (this.homeHeroVideo && this.homeHeroVideo.tagName == "VIDEO") {
                    // Once hero is out of view lets pause the video
                    if (homeHeroBoundingRec.bottom <= 0) {
                        this.homeHeroVideo.pause();
                    } else {
                        this.homeHeroVideo.play();
                    }
                }

                // TODO: Move to Module
                let st = window.pageYOffset || document.documentElement.scrollTop;
                this.lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
                if (!homeHeroBoundingRec) {
                    return;
                }
            }
        } catch (e) {
            return e;
        }
    }

    loadLottiePlayer() {
        const self = this;
        if (self.lottiePlayer) {
            lottie.loadAnimation({
                container: self.lottiePlayer, // the dom element that will contain the animation
                renderer: "svg",
                speed: 1,
                loop: true,
                autoplay: true,
                rendererSettings: {
                    preserveAspectRatio: "xMidYMid meet"
                },
                path: self.lottiePlayer.getAttribute("data-json") // the path to the animation json
            });
        }
    }
}

new InnovatePage();
