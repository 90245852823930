/* AUTOPLAY */
var autoplayON;
function autoplay() {
    autoplayON = setInterval(function() {
        handleNextArrowClick();
    }, 22000);
}

function autoplayOFF() {
    clearInterval(autoplayON);
}

window.onload = autoplay;
/* end:AUTOPLAY */

const indicators = document.querySelectorAll(".fullwidth_slider .indicators2 .indicator");
const arrowPrev = document.querySelector(".fullwidth_slider .arrows .arrow-prev");
const arrowNext = document.querySelector(".fullwidth_slider .arrows .arrow-next");

const handleIndicatorClick = event => {
    const indicator = event.target;
    if (!isActive(indicator)) {
        removeActive();
        addActive(indicator);
        showSlide(indicator.dataset.slide);
    }
};

const handlePrevArrowClick = () => {
    let activeSlide = 0;
    let newActiveSlide = indicators.length;
    let ready = false;

    indicators.forEach(indicator => {
        if (isActive(indicator) && !ready) {
            activeSlide = indicator.dataset.slide;
            if (activeSlide !== "1") {
                newActiveSlide = parseInt(activeSlide) - 1;
            }
            removeActive();
            addActive(document.querySelector(`.fullwidth_slider .indicators2 [data-slide='${newActiveSlide}']`));
            showSlide(newActiveSlide);
            ready = true;
        }
    });
};

const handleNextArrowClick = () => {
    let activeSlide = 0;
    let newActiveSlide = 1;
    let ready = false;

    indicators.forEach(indicator => {
        if (isActive(indicator) && !ready) {
            activeSlide = indicator.dataset.slide;
            if (activeSlide !== indicators.length.toString()) {
                newActiveSlide = parseInt(activeSlide) + 1;
            }
            removeActive();
            addActive(document.querySelector(`.fullwidth_slider .indicators2 [data-slide='${newActiveSlide}']`));
            showSlide(newActiveSlide);
            ready = true;
        }
    });
};

indicators.forEach(indicator => {
    indicator.addEventListener("click", handleIndicatorClick);
});

if (arrowPrev != null) arrowPrev.addEventListener("click", handlePrevArrowClick);
if (arrowNext != null) arrowNext.addEventListener("click", handleNextArrowClick);

function isActive(indicator) {
    return indicator.hasAttribute("active");
}

function removeActive() {
    document.querySelectorAll(".fullwidth_slider .indicators2 [active]").forEach(item => {
        item.removeAttribute("active");
    });
}

function addActive(indicator) {
    indicator.setAttribute("active", "");
}

function showSlide(newActiveSlide) {
    const newPosition = (100 * (newActiveSlide - 1)).toString();
    document.querySelector(".slider-inner").style.marginLeft = `-${newPosition}%`;
}

export default { autoplay, autoplayOFF };
