import debounce from "debounce";
let pillarsFeatureBand;
let pillarsDisplay;
let pillarsItems;
let pillarsWrap;
let pillarFeatureItems;

let isPillarsFixed = false;

const pillarItemConfig = function(pillar) {
    const selector = pillar.getAttribute("id");
    const pillarItem = pillarsFeatureBand.querySelector(`.pillarsFeatureItem[data-id="${selector}"]`);
    const pillarItemBackground = pillarsFeatureBand.querySelector(`.pillarsFeatureItem__backgroundContainer[data-id="${selector}"]`);

    return {
        selector,
        pillarItem,
        pillarItemBackground
    };
};

const pillarSetActive = function(pillarConfig) {
    if (pillarConfig.pillarItem && !pillarConfig.pillarItem.classList.contains("js-active")) {
        if (pillarConfig.pillarItemBackground) {
            if (!pillarConfig.pillarItemBackground.classList.contains("js-active")) {
                const activeBackground = pillarsFeatureBand.querySelector(".pillarsFeatureItem__backgroundContainer.js-active");

                if (activeBackground) {
                    activeBackground.classList.remove("js-active");
                }

                pillarConfig.pillarItemBackground.classList.add("js-active");
            }
        }

        if (pillarConfig.pillarItem) {
            if (!pillarConfig.pillarItem.classList.contains("js-active")) {
                const activeItem = pillarsFeatureBand.querySelector(".pillarsFeatureItem.js-active");

                if (activeItem) {
                    activeItem.classList.remove("js-active");
                }

                pillarConfig.pillarItem.classList.add("js-active");
            }
        }
    }
};

const pillarActivate = function(pillar) {
    // get center pos of item, then center of screen, then add 1 to force us to be over the mid point
    let yPos = Math.ceil(pillarGetCenterPos(pillar) - window.innerHeight / 2) + 1;
    window.scrollBy({
        top: yPos
    });
};

const pillarGetCenterPos = function(pillar) {
    let pillarBox = pillar.getBoundingClientRect();
    return pillarBox.height / 2 + pillarBox.top;
};
const pillarIsCentered = function(pillarSpacer) {
    let midTarget = window.innerHeight / 2;
    let topPos = pillarGetCenterPos(pillarSpacer);
    if (midTarget > topPos) {
        return true;
    }
    return false;
};

const pillarsInnerScrolling = function() {
    let itemToActivate;
    for (let i = pillarsItems.length - 1; i > 0; --i) {
        if (pillarIsCentered(pillarsItems[i])) {
            itemToActivate = pillarItemConfig(pillarsItems[i]);
            break;
        }
    }
    if (itemToActivate) {
        pillarSetActive(itemToActivate);
    }
};

const checkPillarsFeatureBand = function() {
    let boundingRec = pillarsWrap.getBoundingClientRect();
    let windowHeight = window.innerHeight;
    let bottomInView = boundingRec.bottom - windowHeight < 0;

    if (boundingRec.top <= 0) {
        pillarsFeatureBand.classList.add("pillarsFeature--isInFocus");
        isPillarsFixed = true;
    } else {
        pillarsFeatureBand.classList.remove("pillarsFeature--isInFocus");
        isPillarsFixed = false;
    }

    if (bottomInView) {
        pillarsFeatureBand.classList.add("pillarsFeature--isAtBottom");
        pillarsFeatureBand.classList.remove("pillarsFeature--isInFocus");
        isPillarsFixed = false;
    } else {
        pillarsFeatureBand.classList.remove("pillarsFeature--isAtBottom");
    }
};

const checkPillarActiveStatus = function() {
    checkPillarsFeatureBand();
    debounce(function() {
        pillarsInnerScrolling();
    }, 10)();
};

const bindTitleClicks = function() {
    document.querySelectorAll(".js-pillarsFeatureItem__title").forEach(function(trigger) {
        trigger.addEventListener("click", function(e) {
            e.preventDefault();
            let id = this.getAttribute("href").replace("#", "");
            let pillar = document.getElementById(id);
            pillarActivate(pillar);
        });
    });
};

const goToNextPillar = function() {
    pillarFeatureItems.forEach(function(item, index) {
        if (index <= pillarFeatureItems.length - 1 && item.classList.contains("js-active")) {
            let target = document.getElementById(pillarFeatureItems[index + 1].getAttribute("data-id"));
            pillarActivate(target);
        }
    });
};

const goToPrevPillar = function() {
    pillarFeatureItems.forEach(function(item, index) {
        if (index >= 0 && item.classList.contains("js-active")) {
            let target = document.getElementById(pillarFeatureItems[index].getAttribute("data-id"));
            pillarActivate(target);
        }
    });
};

const bindArrows = function() {
    window.addEventListener("keyup", function(e) {
        if (isPillarsFixed) {
            if (e.keyCode == 38) {
                goToPrevPillar();
            }
            if (e.keyCode == 40) {
                goToNextPillar();
            }
        }
    });
};

const linkEvent = () => {
    const pagename = document.querySelector("title").textContent;
    const links = document.querySelectorAll(".pillarsFeature__wrap a");

    links.forEach(el => {
        const label = el.dataset.label;
        const event = el.dataset.event;
        const url = el.getAttribute("href");

        if (!label && !event) return false;

        el.addEventListener("click", () => {
            const data = {
                event: event,
                page: {
                    category: pagename,
                    label: `${label}`,
                    path: url
                }
            };
            window.dataLayer.push(data);
        });
    });
};

function init() {
    pillarsFeatureBand = document.querySelector(".pillarsFeature");
    if (pillarsFeatureBand !== null) {
        pillarsDisplay = document.querySelector(".pillarsFeature__display");
        pillarsItems = pillarsDisplay.querySelectorAll(".pillarsFeatureItem__spacer");
        pillarsWrap = document.querySelector(".pillarsFeature__wrap");
        pillarFeatureItems = document.querySelectorAll(".pillarsFeatureItem");

        if (!document.body.classList.contains("magnolia-editmode")) {
            bindTitleClicks();
            bindArrows();
            checkPillarActiveStatus();
            linkEvent();
            window.addEventListener("scroll", function() {
                checkPillarActiveStatus();
            });
            window.addEventListener("resize", function() {
                checkPillarActiveStatus();
            });
            window.addEventListener("orientationchange", function() {
                checkPillarActiveStatus();
            });
        }
    }
}

export default { init };
