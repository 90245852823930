// eslint-disable-next-line no-unused-vars
const handleNextArrowClick = _ => {
    let activeSlide = 0;
    let newActiveSlide = 1;
    let ready = false;

    indicators.forEach(indicator => {
        if (isActive(indicator) && !ready) {
            activeSlide = indicator.dataset.slide;
            if (activeSlide !== indicators.length.toString()) {
                newActiveSlide = parseInt(activeSlide) + 1;
            }
            removeActive();
            let valInd = document.querySelector(`.indicators [data-slide='${newActiveSlide}']`);
            if (valInd != null) {
                addActive(valInd);
                showSlide(newActiveSlide);
                ready = true;
            } else {
                ready = false;
            }
        }
    });
};

const sliders = document.getElementsByClassName("home_overview__container__row__slider__inner__slide");

var stringContent = "";
for (let i = 0; i < sliders.length; i++) {
    stringContent += '<div class="indicator" data-slide="' + (i + 1) + '" ' + (i == 0 ? "active" : "") + "></div>";
}

let div = document.getElementById("indicators");

if (div) div.innerHTML = stringContent;

const indicators = document.querySelectorAll(".indicator");

const handleIndicatorClick = event => {
    const indicator = event.target;
    if (!isActive(indicator)) {
        removeActive();
        addActive(indicator);
        showSlide(indicator.dataset.slide);
    }
};

// var stringContent;
indicators.forEach(indicator => {
    indicator.addEventListener("click", handleIndicatorClick);
});

function isActive(indicator) {
    return indicator.hasAttribute("active");
}

function removeActive() {
    document.querySelectorAll(".indicators [active]").forEach(item => {
        item.removeAttribute("active");
    });
}

function addActive(indicator) {
    indicator.setAttribute("active", "");
}

function showSlide(newActiveSlide) {
    const newPosition = (100 * (newActiveSlide - 1)).toString();
    let show = document.querySelector(".home_overview__container__row__slider__inner");
    if (show) {
        show.style.marginLeft = `-${newPosition}%`;
    }
}

/* AUTOPLAY */

function autoplay() {
    setInterval(function() {
        handleNextArrowClick();
    }, 7500);
}

function autoplayOFF() {
    clearInterval(autoplay());
}

window.onload = autoplay;
/* end:AUTOPLAY */

export default { autoplay, autoplayOFF };
